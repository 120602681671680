<template>
    <Courses/>
</template>
<script>
import Courses from "@/components/courses/Courses.vue";
export default {
  name: "Cursos",
  components: {
    Courses,
  },
};
</script>
